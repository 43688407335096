<template>
  <div class="form-div">
      <Form class="isForm" label-position="right" :label-width="100">
          <FormItem>
              <span slot="label" class="validate">关联类型:</span>
              <RadioGroup v-model="formData.linkType">
                <Radio label="1">内容详情</Radio>
                <Radio label="2">内容列表</Radio>
                <Radio label="3">页面</Radio>
                <Radio label="4">第三方链接</Radio>
                <Radio label="5">小程序</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">跳转链接:</span>
              <Input placeholder="请填写跳转链接，请加上http或https" :maxlength="50" v-model.trim="formData.linkUrl" />
          </FormItem>
          <FormItem>
              <span slot="label">背景:</span>
              <RadioGroup v-model="formData.businessType">
                <Radio label="4">缩略图</Radio>
                <Radio label="3">图片</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem class="image" v-if="formData.businessType && formData.businessType != ''">
              <span slot="label" class="validate">上传图片:</span>
              <Input placeholder="上传后的链接地址" :disabled="true" v-if="UploadFileUrl && UploadFileUrl.length != 0" v-model="UploadFileUrl[0].url" />
              <LiefengUpload
                     target="1"
                      @success="uploadSuccess"
                      v-if="showLieFengUpload"
                      type="image"
                      accept=".jpg,.png,.gif,.jpeg"
                      :defaultList="UploadFileUrl"
                      :format="['jpg', 'png', 'jpeg', 'gif']"
                      :showView="true"
                      :showDel="false"
                    ></LiefengUpload>
                <Button type="error" v-if="UploadFileUrl.length != 0" @click="deleteExpand">删除背景图片</Button>
          </FormItem>
      </Form>
  </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload";
export default {
    components:{LiefengUpload},
    props:{
        formData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        UploadFileUrl:{
            type:Array,
            default:()=>{
                return []
            }
        },
    },
    data(){
        return{
            showLieFengUpload:true
        }
    },
    methods:{
        uploadSuccess(target,val){
            this.$emit('uploadSuccess',val)
        },
        // 营业执照删除按钮
        deleteExpand(){
            this.showLieFengUpload = false
            this.$nextTick(()=>{
                this.showLieFengUpload = true
            })
            this.$emit('deleteExpand')
        },
    },
    watch:{
        UploadFileUrl:{
            handler(val){
                if(val)
                this.showLieFengUpload = false
                this.$nextTick(()=>{
                    this.showLieFengUpload = true
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.image{
    /deep/.ivu-form-item-content{
      display:block;
    }
}
</style>